import { Injectable } from '@angular/core';
import { ServicelinksService } from '../shared/servicelinks.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreatebulkletterService {

  getheadersSub: Subscription;
  getHeaders: any;
  postHeaders: any;
  postHeadersmulitpart: any;
  postheadersSub: Subscription;
  postheadersMulitpartSub: Subscription;
  constructor(private servicelinks: ServicelinksService, private http: HttpClient, public dataService: DataService) {
    this.getheadersSub = this.dataService.getHeadService.subscribe(message => this.getHeaders = message);
    this.postheadersSub = this.dataService.postHeaderService.subscribe(message => this.postHeaders = message);
    this.postheadersMulitpartSub = this.dataService.postHeaderServiceMultipart.subscribe(message => this.postHeadersmulitpart = message);
  }
  async getReturnTypeCode(templateId: Number): Promise<any> {
    let url = this.servicelinks.returnTypecodeUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.get(url + 'templateId=' + `${templateId}`, { headers: this.getHeaders }).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url + 'templateId=' + `${templateId}`, { headers: this.getHeaders }).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async getAllPartners(): Promise<any> {
    //get
    let url = this.servicelinks.partnersUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async gettemplates(): Promise<any> {
    //get
    let url = this.servicelinks.gettemplatesurl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getbatchidlist(): Promise<any> {
    //get
    let url = this.servicelinks.getbatchidlisturl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getbatchlist(): Promise<any> {
    //get
    let url = this.servicelinks.getbatchlisturl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }

  async postGetClientData(data: any): Promise<any> {
    //post
    let url = this.servicelinks.getfetchclientdata;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async getArrangeFieldValues(batchid: any): Promise<any> {
    //get
    let url = this.servicelinks.arrangefieldvalues;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }


  async getSaveBatchLetters(batchid: any): Promise<any> {
    //get
    let url = this.servicelinks.savebatchLetters;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getCheckFieldValues(batchid: any): Promise<any> {
    //get
    let url = this.servicelinks.checkfieldvalues;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async postlistClientdata(data: any): Promise<any> {
    //post
    let url = this.servicelinks.fetchClientdetails;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async getActiveclientFields(clientId: any, partnerId: any): Promise<any> {
    //post
    let url = this.servicelinks.getActiveclientFields;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}${clientId + "&partnerId=" + partnerId}`, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}${clientId + "&partnerId=" + partnerId}`, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }


  async getbatchlistbyid(batchid: any): Promise<any> {
    //get
    let url = this.servicelinks.getbatchlistbyidurl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }

  async getPartnerOfficeByBatchId(batchid: any): Promise<any> {
    //get
    let url = this.servicelinks.getPartnerOfficeListUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }


  //deleteBatch
  async deleteBatchById(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.deleteBatchUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }


  //deleteBulkletter
  async deleteBulkLetterById(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.deleteBulkLetterUrl;
    //let url = "https://localhost:44324/api/BulkLetters/DeleteByBulkLetterId";
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async downloadletter(data: any): Promise<any> {

    let url = this.servicelinks.downloadletterurl;
    //let url = "https://localhost:44324/api/Template/UploadHTMLLetter";
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async getListActiveFieldData(): Promise<any> {
    //get
    let url = this.servicelinks.fieldListActiveUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }
  async addnewclient(data: any): Promise<any> {

    let url = this.servicelinks.addnewclient;
    //let url = "https://localhost:44324/api/Client/AddClientBulkDetails"
    //let url = "https://localhost:44324/api/Template/UploadHTMLLetter";
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async addbatchrequest(data: any): Promise<any> {

    let url = this.servicelinks.addbatchrequest;
    //let url = "https://localhost:44324/api/BulkLetters/AddBatchRequestDetails"
    //let url = "https://localhost:44324/api/Template/UploadHTMLLetter";
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async AddNewFlowBatchRequest(data: any): Promise<any> {

    let url = this.servicelinks.AddNewFlowBatchRequest;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }




  async updateAttachmentsBulkLetter(data: any): Promise<any> {
    let url = this.servicelinks.updateAttachmentsBulkLetter;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async updateAttachmentsBulkClientLetter(data: any): Promise<any> {
    let url = this.servicelinks.updateAttachmentsBulkClientLetter;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async newupdateAttachmentsBulkClientLetter(data: any): Promise<any> {
    let url = this.servicelinks.newupdateAttachmentsBulkClientLetter;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async newupdateAttachmentsBatchClientLetter(data: any): Promise<any> {
    let url = this.servicelinks.newupdateAttachmentsBatchClientLetter;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async getattachmentByID(batchId: Number, templateId: Number): Promise<any> {
    let url = this.servicelinks.getAttachmentByID;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.get(url + 'batchId=' + `${batchId}` + '&templateId=' + `${templateId}`, { headers: this.getHeaders }).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url + 'batchId=' + `${batchId}` + '&templateId=' + `${templateId}`, { headers: this.getHeaders }).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async geteditFieldsBulklettersbyid(bulkletterid: any): Promise<any> {
    //get
    let url = this.servicelinks.geteditFieldsBulklettersbyid;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${bulkletterid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${bulkletterid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }

  async getCombinedPDFURLBulkLetter(data: any): Promise<any> {
    let url = this.servicelinks.getCombinedPDFURLBulkLetter;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async postUpdateeditFields(data: any): Promise<any> {
    //post
    let url = this.servicelinks.UpdateeditFields;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async getbatchDetailsbyid(batchid: any, userName: string): Promise<any> {
    //get
    let url = this.servicelinks.getbatchletterAsync;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url + 'batchId=' + `${batchid}` + '&userName=' + `${userName}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async updateBulkGenIsBatchActive(data: any): Promise<any> {
    //post
    let url = this.servicelinks.updateBulkGenIsBatchActive;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async exportdata(data: any): Promise<any> {
    //post
    let url = this.servicelinks.exportdata;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async UpdateExistingPartnersRecord(data: any): Promise<any> {
    //post
    let url = this.servicelinks.UpdateExistingPartnersRecord;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async importdata(data: any): Promise<any> {
    //post
    let url = this.servicelinks.importdata;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeadersmulitpart).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeadersmulitpart).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }



  //deleteBulkletter
  async deleteBulkClientLetterById(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.deleteBulkClientLetterUrl;
    //let url = "https://localhost:44324/api/BulkLetters/DeleteByBulkLetterId";
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async getTemplateLogicByName(templatename: any): Promise<any> {
    //get
    let url = this.servicelinks.getTemplateLogicByName;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${templatename}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${templatename}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }
  async getbulklettertemplatebyid(data: any): Promise<any> {
    //bulklettertemplate
    let url = this.servicelinks.gettemplatelogicbyid;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async updateblockcontentdashboard(data: any): Promise<any> {
    //bulklettertemplate
    let url = this.servicelinks.updateblockcontentbyid;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  //blockcontent
  async getblockcontentbyname(data: any): Promise<any> {
    //post
    let url = this.servicelinks.getblockscontentbyname;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  //block content List
  async getblockcontentbynamelist(data: any): Promise<any> {
    //post
    let url = this.servicelinks.getBlocksContentByAllNames;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }



  //updateblockcontentbatch
  async updateblockcontentbatch(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.updatebatchtemplatecontent;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  //Individual & bulk add Attchment
  async getAddAttachmentById(data: any): Promise<any> {
    //bulklettertemplate
    let url = this.servicelinks.bulkAttachmentbyIdUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async newbulkAttachmentbyIdUrl(data: any): Promise<any> {
    //bulklettertemplate
    let url = this.servicelinks.NewbulkAttachmentbyIdUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async newbulkbatchAttachmentbyIdUrl(data: any): Promise<any> {
    //bulklettertemplate
    let url = this.servicelinks.newbulkbatchAttachmentbyIdUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
  async getCreateFormEdit(data: any): Promise<any> {
    //bulklettertemplate
    let url = this.servicelinks.CreateFormEdit;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async postCreateBatchForm(data: any): Promise<any> {

    let url = this.servicelinks.CreateBatchForm7216;

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }


  // Letter creation using clientID/email Start --

  //ClientId/email
  async getAllClientIdName(): Promise<any> {
    //get
    let url = this.servicelinks.clientdetailsUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  //Post basic client details (accordian 1)
  async postClientDetailsUsingClientIdEmail(data: any): Promise<any> {
    //post
    let url = this.servicelinks.fetchClientdetailsUsingClientIdEmailUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  //Post Client details(accrordian 2)
  //updateblockcontentbatch
  async saveClientData(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.SaveClientDataUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async getClientDataById(batchid: any): Promise<any> {
    //get
    let url = this.servicelinks.getclientdatalistbyid;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }

  // Letter creation using clientID/email end --

  //Get basic client details (accordian 3)
  async getAllBulkLetterlist(batchId: Number): Promise<any> {
    let url = this.servicelinks.getAllBulkLetterlistUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.get(url + 'batchId=' + `${batchId}`, { headers: this.getHeaders }).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url + 'batchId=' + `${batchId}`, { headers: this.getHeaders }).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  //Client & bulk letters grid update
  async getAllClientIdBulkLettersDataById(batchid: any): Promise<any> {
    //get
    let url = this.servicelinks.getAllClientIdBulkLettersByIdUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${batchid}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }

  async getOfficeDetailsById(OfficeId: any): Promise<any> {
    //get
    let url = this.servicelinks.getOfficeDetailsById;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${OfficeId}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${OfficeId}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );


  }

  //Partner ClientId/email

  async PostPartnerClientIdName(data: any): Promise<any> {
    //post
    let url = this.servicelinks.partnerclientdetailsUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  // add clinet in New flow
  async addClientNewFlow(data: any): Promise<any> {
    let url = this.servicelinks.addClientNewFlowUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }
}



